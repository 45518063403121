:root {

  // AUTOPLUS 
  /* 
  --main-bg-color: #e02730;
  --main-bg-color2: #000;
  --tr-even: #f5f5f5;
  --tr-hover: #ffe0e0;
  --main-color-in-bg: #fff;
  --loading-color: #e02730;
  --filter-image-loading: invert(20%) sepia(88%) saturate(5460%) hue-rotate(351deg) brightness(86%) contrast(122%);

  --filter-image: invert(20%) sepia(88%) saturate(5460%) hue-rotate(351deg) brightness(86%) contrast(122%);
  --light-main-bg: rgba(255, 100, 100, 0.3);

  --light-gray-bg: rgba(190, 190, 190, 0.3);

 */

  // GAP
  --main-bg-color: #f19b00;
  --main-color-in-bg: #fff;
  --main-bg-color2: #000;
  --loading-color: #000;
  --tr-even: #f5f5f5;
  --tr-hover: rgba(241, 155, 0, 0.2);
  --filter-image: invert(70%) sepia(92%) saturate(432%) hue-rotate(345deg) brightness(93%) contrast(115%);
  --filter-image-loading: invert(70%) sepia(92%) saturate(432%) hue-rotate(345deg) brightness(93%) contrast(115%);

  --light-main-bg: rgba(241, 155, 0, 0.3);

  --light-gray-bg: rgba(190, 190, 190, 0.3);


  /* --main-bg-color: #000;
  --main-color-in-bg: #fff;
  --main-bg-color2: #000;
  --loading-color: #000;
  --tr-even: #f5f5f5;
  --tr-hover: rgba(254, 211, 55, 0.2);
  --filter-image: grayscale(100%);
  --filter-image-loading: grayscale(100%);

  --light-main-bg: rgba(100, 100, 100, 0.1);

  --light-gray-bg: rgba(190, 190, 190, 0.3); */

}